@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(234, 234, 234);
}
/* rgb(234, 234, 234) */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
