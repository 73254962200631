.main_con {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 230px;
  background-color: black;
  color: white;
  height: 100vh;
  position: fixed;
  padding: 1em;
  transition: all 0.5s ease;
}
.main_con_shrunk {
  width: 100px;
}
h1 {
  text-align: center;
}
.opacity_util {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.main_con.main_con_shrunk .opacity_util {
  opacity: 0;
}
.link_con {
  display: flex;
  gap: 1em;
  align-items: center;
  padding: 0.5em 1em;
  border: 1px solid black;
}
.link_con:hover {
  border: 1px solid white;
  border-radius: 10px;
}
.link_selected {
  border: 1px solid white;
  border-radius: 10px;
}
.icon {
  width: 28px;
}
.links_con {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  color: white;
  margin: 2em 0;
}
.menu_link {
  color: white;
}

.footer_ext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.6s ease;
}
.footer_shrunk {
  display: flex;
  justify-content: center;
}
.footer_shrunk > p {
  display: none;
}
.left_arrow {
  cursor: pointer;
  transition: all 0.5s ease;
}
.left_arrow:hover {
  transform: scale(1.2);
}
.btn_con {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}
