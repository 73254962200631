.main_con {
  display: flex;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.main_con:hover {
  transform: scale(1.1);
}
.main_con .span {
  width: 7px;
  background-color: black;
  border-radius: 10px;
}
.count_span {
  font-weight: 700;
  font-size: 1.2em;
}
.hero_con {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.sub_con {
  padding: 1em;
  background-color: white;
  border-radius: 10px;
  display: flex;
  gap: 0.5em;
}

.img_con {
  width: 50px;
  height: auto;
}
.icon {
  max-width: 100%;
  height: auto;
}
