.main_con {
  display: flex;
}
.outlet_con {
  padding: 5em;
  position: absolute;
  left: 250px;
  transition: all 0.3s ease-in-out;
  width: calc(100% - 250px);
}
.extended_outlet {
  left: 150px;
}
