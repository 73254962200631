.main_con {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  gap: 10em;
}
.login_form_con {
  padding: 3em;
  background-color: white;
  border-radius: 10px;
}
.form_input_con {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.form_input_con > input {
  border: 1px solid;
  border-radius: 5px;
  padding: 0.3em 0.5em;
}
form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.heading_con {
  background-color: black;
}
.heading_con h2 {
  color: white;
  text-align: center;
  margin-bottom: 2em;
}
