.main_con {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.card_con {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}
.card_con > * {
  flex: 1;
}
